/**
 * Application base.
 * @module App
 */
import BaseBarba from './barba'
import BaseUtils from '@/utils'
import BaseComponent from '@/models/BaseComponent'
import BaseMixins from '@/utils/mixins'

/**
 * Barba Dispatcher event names map.
 * @const
 * @private {Array}
 */
const Dispatcher = [
  "linkClicked",
  "initStateChange",
  "newPageReady",
  "transitionCompleted"
]

/**
 * Lookup runtime options set in the server response (extends App)
 * @type {Object}
 */
const RuntimeOptions = window.App||{}

// Define the application base
const App = BaseUtils.extend({
  /**
   * @type   {Getter}
   * @return {Object}
   */
  get Utils() {
    return BaseUtils
  },

  /**
   * @type   {Getter}
   * @return {Object}
   */
  get Barba() {
    return BaseBarba
  },

  /**
   * @type   {Getter}
   * @return {Object}
   */
  get Progress() {
    return BaseBarba.Progress
  },

  /**
   * @type   {Getter}
   * @return {Object}
   */
  get Cache() {
    return BaseBarba.BaseCache
  },

  /**
   * @type   {Getter}
   * @return {Object}
   */
  get Component() {
    return BaseComponent
  },

  /**
   * Types of constructable descriptors that can be collected for reference
   * @type {Object}
   */
  get Descriptor() {
    return {
      "module": "$ref.modules",
      "view": "$ref.views"
    }
  },

  /**
   * Determine if this truly is a touch device
   * @return {boolean}
   */
  get isTouchDevice() {
    return BaseUtils.detect.deviceType === 'touchOnly'
  },

  /**
   * Returns the active Barba container
   * @return {HTMLElement}
   */
  get $container() {
    return $(BaseBarba.Pjax.Dom.getContainer())
  },

  /**
   * Define Barba callbacks using an object or function as a parameter.
   * An object parameter must contain a handler function to dispatch.
   * @return {Object}
   */
  get $on() {
    /**
     * Proxy wrapper which defines the callback handler for each dispatched
     * event and passed to Barba.Dispatcher for listening.
     * @param {String} e The event name which will be triggered
     * @param {Function|Object} fn The callback function executed on emit
     */
    const proxy = (e, fn) => {
      if (typeof fn === 'function') {
        if (e === 'newPageReady')
          BaseBarba.Dispatcher.on(e, (...args) => fn.apply(fn, BaseUtils.arrayItem(args, 2, 0)))
        else
          BaseBarba.Dispatcher.on(e, (...args) => fn.apply(fn, args))
      }
      else if (typeof fn === 'object' && fn.hasOwnProperty('handler'))
        BaseBarba.Dispatcher.on(e, (...args) => fn.handler.apply(fn, args))
      else throw "[App.$on] Unsupported Symbol passed as parameter"

      return this
    }
    // Programatically create methods attached to this getter
    // The "Dispatchers" constant defines these methods
    let dispatchers = {}
    Dispatcher.forEach(x => { dispatchers[x] = fn => proxy(x, fn) })
    return Object.create(dispatchers)
  },

  /**
   * Application (git) revision and (time of) build
   * @type {Object}
   */
  version: {
    revision: __COMMIT_NAME__,
    build: __COMMIT_HASH__
  },

  /**
   * CSRF token config subject to Craft values
   * @type {Object}
   */
  csrfToken: {
    name: '',// string
    value: ''// string
  },

  /**
   * Set to "desktop" or "mobile" subject to Craft values
   * @type {String}
   */
  deviceType: null,

  /**
   * Locales available are actually Craft sites
   * @type {Array}
   */
  locales: [],

  /** @type {DOM} */
  $window: $(window),

  /**
   * @type {HTMLElement}
   */
  $document: $(document),

  /**
   * View extends application
   * @param {Object} obj The view object definition
   */
  extend(id, obj) {
    obj.transition = $.extend({}, BaseBarba.BaseView, obj.transition||{})
    delete obj.transition.extend
    obj.transition.namespace = id
    return this.create(this.Descriptor.view, id, obj)
  },

  /**
   * Defines component as App subclass
   * @param  {String} id 
   * @param  {Object} obj 
   * @return {Object}
   */
  module(id, obj) {
    return this.create(this.Descriptor.module, id, obj)
  },

  /**
   * Creates a runtime reference for components, modules and views.
   * These are referenced by their Descriptor (see defined constant)
   * @param  {String} prop 
   * @param  {String} id 
   * @param  {Object} obj 
   * @return {Object}
   */
  create(prop, id, obj) {
    if (!this.hasOwnProperty(prop))
      Object.defineProperty(this, prop, {value:Object.create(Object.getPrototypeOf(this))})

    if (this[prop].hasOwnProperty(id))
      throw new Error(`"${id}" is already defined in the ${prop} descriptor.`)
    
    let newObj = Object.create(Object.getPrototypeOf(obj))

    if (obj.hasOwnProperty('construct'))
      Object.defineProperty(newObj, 'construct', {value:obj.construct})

    let params = [newObj, obj.data||{}, obj.methods||{}]

    // Mixins
    params.push(BaseMixins.plugins)

    // Inlcude transition obj for views only
    if (prop === this.Descriptor.view)
      params.push(obj.transition||{})

    // Extend objects with params
    newObj = BaseUtils.extend.apply(null, params)
    Object.defineProperty(this[prop], id, {value:newObj})

    if (prop === this.Descriptor.view)
      this[prop][id].init()

    return this[prop][id]
  },

  /**
   * Check if we are currently on the primary site
   * @return {Boolean}
   */
  isPrimarySite() {
    const first = BaseUtils.firstSegment(window.location.pathname, false)
    return first !== 'fr'
  },

  /**
   * Getter: Return true if mobile browser detected. Can also be picked up in
   * the runtime config set in server response.
   * Setter: Set property to boolean value
   * @param  {Boolean} value 
   * @return {Boolean}
   */
  isMobileBrowser(value) {
    if (typeof value !== 'undefined') {
      if (typeof value !== 'boolean')
        throw new TypeError('[App] isMobileBrowser expects value to be Boolean')
      this._isMobileBrowser = value
    }
    this._isMobileBrowser = this._isMobileBrowser||false
    return this._isMobileBrowser
  },

  /**
   * App runtime execution
   * @constructor
   * @return {*}
   */
  run() {
    // Expose app in dev environment
    console.log(`%cStrictly Confidential (${App.version.revision}/#${App.version.build})`, 'background:#000;color:#fff;padding:5px 10px;', '\n', (window.App = this))

    // Mobile detection from runtime config
    this.deviceType && this.isMobileBrowser(this.deviceType === 'mobile')

    // Touch detection modifier
    $('html').addClass(this.isTouchDevice ? 'touch' : 'no-touch')

    // Rich to cooke-up some JS (see what I did there)
    this.$window.on('resize', BaseUtils.throttle(event => {
      this.$document.trigger('sc.window.resize', event)
    }, 250))

    
    // IE detection
    if (!!BaseUtils.detectIE())
      $('html').addClass('ie')

    // Construct descriptors
    Object.entries(this.Descriptor).filter(([k,descriptor]) => !!this[descriptor])
    .forEach(([k,descriptor]) => {
      Object.getOwnPropertyNames(this[descriptor]).map(id => {
        if (this[descriptor][id].hasOwnProperty('construct')) {
          this[descriptor][id].construct()
        }
      })
    })

    // Initialize Barba.js
    BaseBarba.init()
  }
}, RuntimeOptions)

export default App